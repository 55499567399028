var render = function render(){var _vm=this,_c=_vm._self._c;return (!this.$can('Read', 'Document'))?_c('div',{staticStyle:{"align-items":"center","padding":"16px","text-align":"center"}},[_c('b-img',{staticStyle:{"scale":"0.8"},attrs:{"fluid":"","src":_vm.notAuthorized,"alt":"Error page"}}),_c('h1',{staticClass:"mt-2"},[_vm._v(_vm._s(_vm.$t('You dont have permission view this page')))])],1):_c('div',[_c('document-form',{attrs:{"entity-id":_vm.entityId,"entity-type":_vm.entityType,"schema-name":_vm.schemaName,"visible":_vm.showForm,"resource-id":_vm.resourceId},on:{"saved":function($event){return _vm.getInitialData()},"close":_vm.onCloseForm}}),_c('document-preview-modal',{attrs:{"entity-id":_vm.entityId,"schema-name":_vm.schemaName,"entity-type":_vm.entityType,"active-document-preview":_vm.activeDocumentPreview}}),_c('b-overlay',{staticClass:"d-flex flex-column",staticStyle:{"height":"100%"},attrs:{"show":_vm.loading,"spinner-variant":"primary","rounded":"sm","variant":"transparent"}},[_c('div',{staticClass:"card resource-view__scrollable"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"mb-2 d-flex"},[_c('button',{staticClass:"btn btn-primary d-flex justify-content-around align-items-center btn-secondary",class:{
              'icon-disabled': _vm.canCreate === false,
            },attrs:{"id":"create-document-button"},on:{"click":function($event){_vm.canCreate && _vm.create()}}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"UserPlusIcon"}}),_c('span',[_vm._v(_vm._s(_vm.$t('Add Document')))])],1),_c('b-tooltip',{attrs:{"target":"create-document-button"}},[_vm._v(" "+_vm._s(_vm.canCreate === true ? _vm.$t('Add Document') : _vm.$t('You dont have permission to add document'))+" ")]),_c('b-button',{class:{
              'icon-disabled': _vm.canCreate === false,
            },staticStyle:{"margin-left":"12px"},attrs:{"id":`generate-doc-button`},on:{"click":function($event){_vm.canCreate && _vm.openModal()}}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"DownloadIcon"}}),_c('span',{staticClass:"align-middle"},[_vm._v(_vm._s(this.$t('Generate Document')))])],1),_c('b-tooltip',{attrs:{"target":"generate-doc-button"}},[_vm._v(" "+_vm._s(_vm.canCreate === true ? _vm.$t('Generate Document') : _vm.$t('You dont have permission to generate document'))+" ")])],1),_c('b-table',{ref:"refTable",staticClass:"table-responsive",attrs:{"busy":_vm.loading,"items":_vm.items,"fields":_vm.tableColumns,"responsive":"","primary-key":"id","show-empty":"","empty-text":_vm.$t('No matching records found')},scopedSlots:_vm._u([{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center my-2"},[_c('b-spinner',{staticClass:"align-middle"})],1)]},proxy:true},{key:"head()",fn:function({ label }){return [_c('span',{staticStyle:{"white-space":"nowrap"}},[_vm._v(_vm._s(_vm.$t(label)))])]}},{key:"cell(name)",fn:function({ item }){return [_c('prozess-resource-name',{attrs:{"has-permission":_vm.$can('Read', 'Document'),"to":{
              name: 'document-view',
                params: {
                  id: item.uuid,
                  tab: _vm.GenericTabs.PREVIEW,
                  },
                },"title":item.name}})]}},{key:"cell(signing)",fn:function({ item }){return [_c('div',{class:_vm.getClassName(item.signingOrder)},[_c('prozess-resource-name',{attrs:{"to":{
                name: 'document-view',
                  params: {
                    id: item.uuid,
                    tab: _vm.GenericTabs.SIGNICAT_SIGNING_REQUESTS,
                    },
                  },"title":_vm.getSigningStatusLabel(item.signingOrder)}})],1)]}},{key:"cell(actions)",fn:function({ item }){return [_c('feather-icon',{staticClass:"mx-1 cursor-pointer",attrs:{"id":`popup-row-${item.uuid}-preview-icon`,"icon":"EyeIcon","size":"16"},on:{"click":function($event){return _vm.handlePreview(item)}}}),_c('b-tooltip',{attrs:{"title":_vm.$t('Preview'),"target":`popup-row-${item.uuid}-preview-icon`,"placement":"left"}}),_c('feather-icon',{staticClass:"mx-1 cursor-pointer",class:{
             'icon-disabled': _vm.canUpdate === false,
            },attrs:{"id":`edit-row-${item.uuid}-preview-icon`,"icon":"EditIcon","size":"16"},on:{"click":function($event){_vm.canUpdate ? _vm.edit(item) : null}}}),_c('b-tooltip',{attrs:{"title":_vm.canUpdate === true ? _vm.$t('Edit') : _vm.$t('You dont have permission to update document'),"target":`edit-row-${item.uuid}-preview-icon`,"placement":"left"}}),_c('feather-icon',{staticClass:"mx-1 cursor-pointer",class:{
                'icon-disabled': _vm.canDelete === false,
              },attrs:{"id":`delete-row-${item.uuid}-preview-icon`,"icon":"TrashIcon","size":"16"},on:{"click":function($event){_vm.canDelete && _vm.confirmRemoval(item)}}}),_c('b-tooltip',{attrs:{"title":_vm.canDelete === true ? _vm.$t('Remove') : _vm.$t('You dont have permission to detach document'),"target":`delete-row-${item.uuid}-preview-icon`,"placement":"left"}})]}}])})],1)])]),_c('GenerateModal',{ref:"genModal"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }