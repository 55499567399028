<template>
     <div v-if="!this.$can('Read', 'Document')" style="align-items: center;padding: 16px; text-align: center;">
      <!-- <p class="">Oops! 😖 You cannot access the requested URL.</p> -->
      <b-img
        style="scale: 0.8;"
          fluid
          :src="notAuthorized"
          alt="Error page"
        />
        <h1 class="mt-2">{{ $t('You dont have permission view this page') }}</h1>
  </div>
  <div v-else>
    <document-form
      :entity-id="entityId"
      :entity-type="entityType"
      :schema-name="schemaName"
      :visible="showForm"
      :resource-id="resourceId"
      @saved="getInitialData()"
      @close="onCloseForm"
    />
    <document-preview-modal
      :entity-id="entityId"
      :schema-name="schemaName"
      :entity-type="entityType"
      :active-document-preview="activeDocumentPreview"
    />
    <b-overlay
      :show="loading"
      spinner-variant="primary"
      rounded="sm"
      variant="transparent"
      style="height: 100%"
      class="d-flex flex-column"
    >
      <div class="card resource-view__scrollable">
        <div class="card-body">
          <div class="mb-2 d-flex">
            <button
              id="create-document-button"
              class="btn btn-primary d-flex justify-content-around align-items-center btn-secondary"
              :class="{
                'icon-disabled': canCreate === false,
              }"
              @click="canCreate && create()"
            >
              <feather-icon
                icon="UserPlusIcon"
                class="mr-50"
              />
              <span>{{ $t('Add Document') }}</span>
            </button>
            <b-tooltip target="create-document-button">
              {{ canCreate === true ? $t('Add Document') : $t('You dont have permission to add document') }}
            </b-tooltip>
            <b-button :id="`generate-doc-button`"  style="margin-left:12px"
            :class="{
                'icon-disabled': canCreate === false,
              }"
             @click="canCreate && openModal()">
              <feather-icon icon="DownloadIcon" class="mr-50"/>
              <span class="align-middle">{{ this.$t('Generate Document') }}</span>
            </b-button>
            <b-tooltip target="generate-doc-button">
              {{ canCreate === true ? $t('Generate Document') : $t('You dont have permission to generate document') }}
            </b-tooltip>
          </div>

          <b-table
          ref="refTable"
          class="table-responsive"
          :busy="loading"
          :items="items"
          :fields="tableColumns"
          responsive
          primary-key="id"
          show-empty
          :empty-text="$t('No matching records found')"
          >
          <template #table-busy>
            <div class="text-center my-2">
              <b-spinner class="align-middle" />
            </div>
          </template>
          <template #head()="{ label }">
            <span style="white-space: nowrap">{{ $t(label) }}</span>
          </template>
          <template #cell(name)="{ item }">
            <prozess-resource-name
              :has-permission="$can('Read', 'Document')"
              :to="{
                name: 'document-view',
                  params: {
                    id: item.uuid,
                    tab: GenericTabs.PREVIEW,
                    },
                  }"
              :title="item.name"
            />
          </template>
          <template #cell(signing)="{ item }">
            <div :class = getClassName(item.signingOrder)>
              <prozess-resource-name
                :to="{
                  name: 'document-view',
                    params: {
                      id: item.uuid,
                      tab: GenericTabs.SIGNICAT_SIGNING_REQUESTS,
                      },
                    }"
                :title="getSigningStatusLabel(item.signingOrder)"
              />
            </div>
          </template>
          <template #cell(actions)="{ item }">
            <feather-icon
              :id="`popup-row-${item.uuid}-preview-icon`"
              icon="EyeIcon"
              size="16"
              class="mx-1 cursor-pointer"
              @click="handlePreview(item)"
              />
            <b-tooltip
              :title="$t('Preview')"
              :target="`popup-row-${item.uuid}-preview-icon`"
              placement="left"
              />

            <feather-icon
              :id="`edit-row-${item.uuid}-preview-icon`"
              icon="EditIcon"
              size="16"
              class="mx-1 cursor-pointer"
              :class="{
               'icon-disabled': canUpdate === false,
              }"
              @click="canUpdate ? edit(item) : null"
              />
              <b-tooltip
                :title="canUpdate === true ? $t('Edit') : $t('You dont have permission to update document')"
                :target="`edit-row-${item.uuid}-preview-icon`"
                placement="left"
              />

              <feather-icon
                :id="`delete-row-${item.uuid}-preview-icon`"
                icon="TrashIcon"
                size="16"
                class="mx-1 cursor-pointer"
                :class="{
                  'icon-disabled': canDelete === false,
                }"
                @click="canDelete && confirmRemoval(item)"
              />
              <b-tooltip
                :title="canDelete === true ? $t('Remove') : $t('You dont have permission to detach document')"
                :target="`delete-row-${item.uuid}-preview-icon`"
                placement="left"
              />
          </template>
        </b-table>
        </div>
      </div>
    </b-overlay>
    <GenerateModal
      ref="genModal"
      />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { GenericTabs, Schema } from '@/constants/app'
import documentAttachmentService from '@/services/documentAttachment'
import documentService from '@/services/document'
import DocumentPreviewModal from '@/views/pages/Documents/ListOnView/DocumentPreviewModal.vue'
import DocumentForm from '@/views/pages/Documents/Form.vue'
import detachOrDeleteMixinFactory from '@/mixins/detachOrDeleteMixin'
import GenerateModal from '@/components/DocumentContract/GenerateModal.vue'

export default {
  name: 'IndexDocuments',
  components: {
    DocumentForm,
    DocumentPreviewModal,
    GenerateModal,
  },

  mixins: [
    detachOrDeleteMixinFactory({
      uuidKey: 'documentUuid',
      versionKey: 'documentVersion',
    }),
  ],

  props: {
    entityType: {
      type: String,
      required: true,
    },

    entityId: {
      type: String,
      required: true,
    },

    schemaName: {
      type: String,
      default: 'crm',
    },

    withEditAction: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      GenericTabs,
      resourceId: null,
      companyId: null,
      showForm: false,
      loading: false,
      items: [],
      customFields: [],
      documentPreviewId: 'documentPreview',
      activeDocumentPreview: null,
      tableColumns: [],
      notAuthorized: require('@/assets/images/pages/not-authorized.svg'),
    }
  },

  computed: {
    canCreate() {
      return this.$can('Create', 'Document')
      // if (this.schemaName === Schema.CUSTOM) {
      //   return this.$can('Create', this.entityType)
      // }

      // switch (this.entityType) {
      //   case 'note':
      //     return this.$can('Create', 'Note')

      //   case 'inquiry':
      //     return this.$can('Create', 'Inquiry')

      //   default:
      //     return this.$can('Create', 'CRM')
      // }
    },
    canUpdate() {
        return this.$can('Update', 'Document')
      // if (this.schemaName === Schema.CUSTOM) {
      //   return this.$can('Update', this.entityType)
      // }

      // switch (this.entityType) {
      //   case 'note':
      //     return this.$can('Update', 'Note')

      //   case 'inquiry':
      //     return this.$can('Update', 'Inquiry')

      //   default:
      //     return this.$can('Update', 'CRM')
      // }
    },

    detachOrDelete() {
      return documentAttachmentService.detachOrDelete
    },

    canDelete() {
      return this.$can('Delete', 'Document')
    },

    handleDeletionCompletion() {
      return this.getInitialData
    },
  },

  watch: {
   '$route.params': {
      handler(val) {
         if (val?.tab === GenericTabs.DOCUMENTS) {
            this.getInitialData()
         }
      },
      immediate: true,
   },
  },

  created() {
    this.tableColumns = [
    { key: 'name', label: 'Name', sortable: true },
    { key: 'signing', label: 'Signing Status', class: 'text-center disabled' },
    { key: 'description', label: 'Description', sortable: true },
    { key: 'actions', class: 'text-center disabled' },
    ]
  },

  methods: {
    getClassName(signingOrder) {
      if (signingOrder === null) {
        return ''
      }
      switch (signingOrder.status) {
        case 'CREATED': return 'signing-label-client'
        case 'CLIENT_SIGNED': return 'signing-label-client'
        case 'CUSTOMER_SIGNED': return 'signing-label-customer'
        case 'COMPLETED': return 'signing-label-signed'
        default: return ''
      }
    },
    getSigningStatusLabel(signingOrder) {
      if (signingOrder === null) {
        return ''
      }
      console.log('signingOrder.status', signingOrder.status)
      switch (signingOrder.status) {
        case 'CREATED': return 'Waiting for Client'
        case 'CLIENT_SIGNED': return 'Waiting for Customer'
        case 'CUSTOMER_SIGNED': return 'Document Signed'
        case 'COMPLETED': return 'Document Signed'
        default: return ''
      }
    },
    handlePreview(item) {
      this.activeDocumentPreview = item
      this.$bvModal.show(this.documentPreviewId)
    },
    openModal() {
      this.$refs.genModal.openModal({
        open: true,
        generateAllRelation: false,
      })
    },
    edit(item) {
      this.resourceId = item.uuid
      this.showForm = true
    },

    async getInitialData() {
      try {
        this.loading = true
        const res = await Promise.all([
          documentAttachmentService.getAll(this.schemaName, this.entityType, this.$route.params.id),
          documentService.getCustomFields(),
        ])
        this.customFields = res[1].data.sort((a, b) => b.favorite - a.favorite)
        this.items = res[0].data.pageItems.reduce((acc, item) => {
          item.customFieldData = item.customFieldData ? item.customFieldData : []
          const processedFields = item.customFieldData
            .map(field => {
              const metadata = this.customFields.find(meta => meta.id === field.metadataId)
              if (!metadata) return null
              return {
                label: metadata.label,
                value: field.value,
                fieldType: metadata.fieldType,
              }
            })
            .filter(field => !!field)
          item.canShowMore = () => processedFields.length
          item.showMoreFields = () => processedFields
          acc.push(item)
          return acc
        }, [])
      } finally {
        this.loading = false
      }
    },

    collapse(item) {
      this.$set(item, 'visible', !item.visible)
    },

    create() {
      this.showForm = !this.showForm
    },

    onCloseForm() {
      this.resourceId = null
      this.showForm = false
    },

    async checkIfDetachOnly(document) {
      const { response } = await this.$async(documentAttachmentService.getOne(this.schemaName, this.entityType, this.$route.params.id, document.uuid))

      return response.data.connectedEntitySingleResponse.length > 1
    },
  },
}
</script>

<style lang="scss">
@import '@/assets/scss/company-contact-list.scss';
.signing-label-signed{
  background-color: rgb(87, 171, 121);
  border-radius: 20px;
  padding: 6px 4px;
  color: white;
}
.signing-label-client{
  background-color: rgb(246, 191, 37);
  border-radius: 20px;
  padding: 6px 4px;
  color: white;
}
.signing-label-customer{
  background-color: rgb(246, 191, 37);
  border-radius: 20px;
  padding: 6px 4px;
  color: white;
}
.signing-label-expired{
  background-color: red;
  border-radius: 20px;
  padding: 6px 4px;
  color: white;
}
</style>
